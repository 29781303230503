/* Core functionality */
.marquee {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	overflow: hidden;
	background-image: linear-gradient(
		to bottom,
		rgba(252, 221, 118, 1),
		rgba(252, 201, 95, 1)
	);
	max-width: 10000px;
}

.marquee > span {
	white-space: nowrap;
	animation: marquee 35s linear infinite;
	max-width: none;
}

@keyframes marquee {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-100%, 0);
	}
}

#hero-text {
	> p {
		@apply md:text-7xl text-4xl;
	}

	> strong {
		@apply md:text-8xl text-5xl;
	}
}

#bottom-CTA-text {
	> strong {
		@apply text-color-wizard-blue;
	}
}

#how-it-works-text {
	> p {
		@apply text-color-sky-blue text-4xl;
	}
}

.floating-cards :nth-child(1) {
	animation-delay: 0.25s !important;
}
.floating-cards :nth-child(2) {
	animation-delay: 0.5s !important;
}
.floating-cards :nth-child(3) {
	animation-delay: 0.75s !important;
}
.floating-cards :nth-child(4) {
	animation-delay: 1s !important;
}
.floating-cards :nth-child(5) {
	animation-delay: 1.25s !important;
}
.floating-cards :nth-child(6) {
	animation-delay: 1.5s !important;
}

.flip-card-desktop {
	width: 350px;
	height: 350px;
	perspective: 1000px;
	transition: transform 0.3s;
}

.flip-card-desktop.order-1 {
	z-index: -10;
	transform: translateX(-350px) translateY(150px) rotateZ(-30deg);
	:hover {
		opacity: 0.75;
		cursor: pointer;
		transition: opacity 0.25s;
	}
}
.flip-card-desktop.order-2 {
	transform: scale(1.2);
}
.flip-card-desktop.order-3 {
	z-index: -10;
	transform: translateX(350px) translateY(150px) rotateZ(30deg);
	:hover {
		opacity: 0.75;
		cursor: pointer;
		transition: opacity 0.25s;
	}
}

.flip-card-mobile {
	width: 250px;
	height: 350px;
	perspective: 1000px;
	transition: transform 0.3s;
}
.flip-card-mobile.order-1 {
	transform: scale(1.4);
}

.flip-card-mobile.order-2 {
	transform: scale(1.15) translateX(7rem);
	z-index: -10;
}
.flip-card-mobile.order-3 {
	transform: translateX(14rem);
	z-index: -20;
}

.flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	transition: transform 0.6s;
	transform-style: preserve-3d;
}

.flip-card-inner-flipped {
	transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.flip-card-front {
	transform: rotateY(180deg);
}
