@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Dokdo&family=Galindo&display=swap');

body {
	font-family: 'Poppins', system-ui, -apple-system, BlinkMacSystemFont,
		'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
		sans-serif;
	font-size: 16px;
	margin: 0;
}

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/utilities/api';
@import '../../../node_modules/bootstrap/scss/bootstrap-grid.scss';
@import '../../../node_modules/bootstrap/scss/spinners';
@import './home.scss';
@import './about_us.scss';
@import './top_bar.scss';
@import './raffles.scss';
@import './raffle_details.scss';
@import './raffle_group.scss';
@import './raffle_group_early_bird.scss';
@import './temps/pre_launch_event.scss';

html,
body {
	height: 100%;
	min-height: 100%;
	overflow-x: hidden;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
